
const config = {
  colorcode: "#7ab4ed",
  colordark: "#263d42",
  colorlight: "#ffffff",
  company: "vib europe GmbH",
  logoURI: "https://utils.vib-development.ch/vib-europe/logo.svg",
  address: [
    "vib europe GmbH", "Wilhelm-Sammet-Straße 87/89", "D-04157 Leipzig"
  ],
  contact: ["+49 175 6185760", "support@vib-europe.de"],
  apibaseuri: "http://localhost:3000",
  appname: "ECM Manual",
  appdomain: "manual.vib-europde.de",
  appurl: "https://manual.vib-europde.de",
  statuspage: "https://status.vib-development.ch",
  support: "support@vib-europe.de"
}

export default config;
