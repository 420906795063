import {FunctionalComponent, h} from "preact";
import style from './style.css';

const Stroke: FunctionalComponent = (props) => (
  <div className={style.stroke}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" preserveAspectRatio="xMidYMin meet">
      <rect
        className={style.first}
        width="1000%"
        height="1000%"
        fill="#7ab4ed"
        transform="rotate(45 100 50) translate(-5 0)"
      ></rect>
      <rect
        className={style.white}
        width="1000%"
        height="1000%"
        fill="#ffffff"
        transform="rotate(45 100 50) translate(-3 0)"
      ></rect>
    </svg>
  </div>
);

export default Stroke;
