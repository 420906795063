import { FunctionalComponent, h} from "preact";
import style from './style.css';

import config from '../../config';

const Logo: FunctionalComponent = (props) => {
  return (<a href={"/"} className={style.logo}>
    <img src={config.logoURI || ''} alt={config.company && `Logo ${config.company}` || ''} className={style.image} />
  </a>);
}

export default Logo;
