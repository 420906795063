import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

import Logo from '../logo';
import {useState} from "preact/hooks";
import {useCallback, useEffect} from "preact/compat";
import axios from "axios";

const Header = () => {
  const [data, dataSet] = useState(null);

  const fetchData = useCallback(async() => {
    // const response = await axios.get(`http://localhost:3000/drive/list`);
    const response = await axios.get(`https://api.develop.vib-connect-flix.de3.amazee.io/drive/list`);
    //console.log(response)
    dataSet(response.data);
  }, []);


  useEffect(() => {
    fetchData()
  }, [fetchData]);

    return (
        <header className={style.header}>
            <Logo />
            <nav className={style.nav} role={"navigation"}>
              <div id={"menuToggle"}>
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id={"menu"}>
                  <li>
                    <Link activeClassName={style.active} href="/">
                      Home
                    </Link>
                  </li>
                  {data && data.nav.map(item => {
                    return (<li>
                      <Link activeClassName={style.active} href={item.url} >
                        {item.text}
                      </Link>
                    </li>);
                  })}
                </ul>
              </div>
            </nav>
        </header>
    );
};

export default Header;
